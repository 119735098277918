'use client';
import { createContext, useContext, useState } from "react";

export const CountriesLocalesContext = createContext({
    countriesLoc: {},
    setCountriesLoc: () => { },
})

export const CountriesLocalesContextProvider = ({ children }) => {
    const [countriesLoc, setCountriesLoc] = useState();
    
    return (<CountriesLocalesContext.Provider value={{ countriesLoc, setCountriesLoc }}>{children}</CountriesLocalesContext.Provider>)
}
export const useCountriesLocalesContext = () => {
    return useContext(CountriesLocalesContext)
}