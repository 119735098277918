import SpinnerScreen from "@/components/molecules/ui/SpinnerScreen";
import { useLangContext } from "@/contexts/LangContext";
import { GET_HOTEL_DATA } from "@/graphql/Hotels.graphql";
import { _ln18 } from "@/lib/functions";
import { useQuery } from "@apollo/client";


export const ResultRowHotels = ({ obj, type, countriesLoc, onClick, inputWidth, ln18 }) => {

    const { loading, error, data } = useQuery(GET_HOTEL_DATA, { variables: { hotelId: obj.id } });

    if (loading) return <SpinnerScreen />

    return (
        <span onClick={() => onClick(type, obj)}
            className={`py-1 px-5 hover:bg-blue-50 hover:text-white cursor-pointer block`}
            style={{ width: inputWidth - 2 }}
        >
            {obj.name}
            <span className="text-blue-10">{obj.country_code ? countriesLoc?.find(c => c.code === obj?.country_code?.toLowerCase())['name'] + ', ' : ''} {_ln18(obj.type, ln18)}</span>
            {data?.hotel?.data?.address && (obj.name, <div className="text-11 text-blue-10 ">{data?.hotel?.data?.address}</div>)}
        </span>
    )
}