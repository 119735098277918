"use client";
import { useSearchParams } from "next/navigation";
import { useRef, useState, useEffect } from "react";
import { useSearchContext } from "@/contexts/SearchContexts";
import InputListSelect from "@/components/atoms/FormUi/InputListSelect";
import { ExpandIcon } from "../../atoms/ExpandIcon";
import { countGuestsFromParam, getDate } from "../../../lib/functions";
import { __DirectiveLocation } from "graphql";

const Input = ({
  field,
  val,
  values,
  className,
  classInput,
  onClick,
  onChange,
  resultList,
  roomID,
  onSort
}) => {
  const [isActiveSelect, setIsActiveSelect] = useState(false);
  const [placeholder, setPlaceholder] = useState(field?.attr?.placeholder);
  const [label, setLabel] = useState(field?.label);
  const [min, setMin] = useState(field?.attr.min); //field?.attr.min || getDate(0, 1)
  const [max, setMix] = useState(field?.attr.max); //field?.attr.max || getDate(365, 1)
  const inputRef = useRef(0);
  const adultRef = useRef(0);
  const [inputWidth, setInputWidth] = useState(null);
  const [value, setValue] = useState(val || field?.attr.placeholder);
  const searchParams = useSearchParams()
  const {
    setDestination,
    setCheckOut,
    destination,
    adult,
    child,
    setAdultRooms,
    rooms,
    residency,
    checkout,
    setCheckInTimeHandler,
    setResidency,
  } = useSearchContext();

  useEffect(() => {
    setPlaceholder(field.attr.placeholder)
  }, [field]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resultList?.hotels)
      setIsActiveSelect(true)
  }, [resultList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (field?.name === "rooms") {
      const searchParamsGuests = searchParams.get('guests');
      let count = 0;

      // if is searchParams
      if (searchParamsGuests) {
        count = countGuestsFromParam(searchParamsGuests)
      } else {

        // if isn't searchParams
        rooms.map((room) => {
          count += room.adult;
          count += room.children;
        });
      }
      setValue(count + " " + field.attr.placeholder);
      setPlaceholder(count + " " + field.attr.placeholder);
      setLabel(rooms.length + " " + field.label);
    }
  }, [adult, child, rooms]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if(searchParams.size == 0){
    //   sessionStorage.clear();
    // }
    if (field?.name === "checkin") {
      const date = searchParams.get('dates') ? searchParams.get('dates').split('-')[0] : getDate(1, 1);
      date ? setValue(getDate(null, 1, date)) : setValue(getDate(1, 1));
      sessionStorage.setItem('checkin', date)
    }
    if (field?.name === "checkout") {
      const date = searchParams.get('dates') ? searchParams.get('dates').split('-')[1] : getDate(2, 1)
      date ? setValue(getDate(null, 1, date)) : setValue(getDate(2, 1));
      sessionStorage.setItem('checkout', date)
    }
    if (field?.name === "destination") {
      // def destination
      let _destination = { id: 3765, name: field.attr.placeholder };

      //isn't saved destination
      if (!sessionStorage.getItem('destination'))
        sessionStorage.setItem('destination', JSON.stringify(_destination))

      sessionStorage.setItem('destination_type', 'region')

      //is saved destination
      const localDestination = JSON.parse(sessionStorage.getItem('destination')) || false;
      const paramRegionId = parseInt(searchParams.get('region_id'));

      if (paramRegionId && localDestination && paramRegionId === localDestination.id) {
        _destination = localDestination;
      }
      if (paramRegionId && !localDestination) {
        // get destination from library
      }
      setDestination(_destination);
      setValue(_destination.name)
    }
    if (field?.name === "checkout" && !checkout) {
      setCheckOut(field?.attr.placeholder);
    }

    // document.addEventListener('mousedown', setIsActiveSelect(false));
    // return () => {
    //   document.removeEventListener('mousedown', setIsActiveSelect(false));
    // };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setInput = (val, fieldName) => {
    setValue(val);
    setIsActiveSelect(false);

    if (fieldName === "child") {
      onChange(roomID, parseInt(val));
    }
    else
      if (fieldName === "early_checkin" || fieldName === "late_checkout") {
        setCheckInTimeHandler(val, fieldName);
      }
      else {
        onChange(fieldName, val);
      }
  };
  const setAdultRoomHandler = (roomId, val) => {
    if (val < field.attr.min) val = field.attr.min;
    if (val > field.attr.max) val = field.attr.max;
    setAdultRooms(roomId, val);
  };
  const addAdult = (roomId) => {
    setAdultRoomHandler(roomId, rooms[roomId].adult + 1);
  };
  const removeAdult = (roomId) => {
    setAdultRoomHandler(roomId, rooms[roomId].adult - 1);
  };
  const onClickResultsHandler = (type, el) => {
    type === "hotels" || type === "regions" ? setActiveHandler(type, el) : "";
    type === "countries" ? setResidencyHandler(el) : "";
    type === 'search' ? sortList(el) : '';
    setIsActiveSelect(false);
  };

  const sortList = (data) => {
    setValue(data.name);
    onSort(data)
  }
  const setActiveHandler = (type, data) => {
    setValue(data.name);
    // console.log('set destination', JSON.stringify(data))
    sessionStorage.setItem('destination', JSON.stringify(data))
    sessionStorage.setItem('destination_type', type.slice(0, -1))
    setDestination(data);
  };
  const setResidencyHandler = (data) => {
    setValue(data.name);
    setResidency(data);
    sessionStorage.setItem('residency', data.code)
    sessionStorage.setItem('residency_name', data.name)
  };
  const onChangeHandler = (str, val) => {
    setValue(str);
    onChange(str);
  };

  return (
    <div
      className={`text-dark z-10 relative wrapper ${className ? className : ""}`}
    >
      {label && <div className="text-xs label">{label}</div>}
      {field?.type === "input" && field.attr.type !== "number" && (<>
        <input
          className={`w-full px-5 py-3 mt-1 rounded-xl relative z-50 border border-blue-8 text-blue-100 text-sm hover:border-blue-10 focus:border:blue-100 ${classInput ? classInput : ""
            }`}
          type={field.attr.type}
          id={field.name}
          ref={inputRef}
          name={field.name}
          min={min}
          max={max}
          autoComplete="off"
          readOnly={field.attr.readOnly}
          disabled={field.attr.disabled}
          value={value}
          onFocus={() => {
            field.attr.etype && field.attr.etype === "select"
              ? setIsActiveSelect(!isActiveSelect)
              : "";
          }
          }
          onClick={onClick}
          onChange={() => onChangeHandler(inputRef.current.value, field.name)}
          placeholder={placeholder}
          /></>
        )}

      {resultList && (<>
        <ExpandIcon isOpen={isActiveSelect} onClick={() => setIsActiveSelect(!isActiveSelect)} className="exp-input" />
        <InputListSelect
          isActiveSelect={isActiveSelect}
          resultList={resultList}
          inputWidth={inputWidth}
          onClick={(type, obj) => onClickResultsHandler(type, obj)}
        />
      </>
      )}

      {/* field input for number of peoples */}
      {field?.type === "input" && field.attr.type === "number" && (
        <div
          className={`rounded-xl border border-blue-8 flex w-1/3 ${className}`}
        >
          <span
            className="text-2xl px-2 cursor-pointer select-none"
            onClick={() => removeAdult(roomID)}
          >
            -
          </span>
          <input
            className={`w-full py-2 ps-4 z-5 relative text-blue-100 text-sm hover:border-blue-10 focus:border:blue-10 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${classInput ? classInput : ""
              }`}
            type={field.attr.type}
            name={field.name}
            ref={adultRef}
            value={rooms[roomID].adult}
            onChange={() =>
              setAdultRoomHandler(roomID, parseInt(adultRef.current.value))
            }
            placeholder={placeholder}
            min={min}
            max={max}
            minLength={min}
            maxLength={max}
          />
          <span
            className="text-2xl px-2 cursor-pointer select-none"
            onClick={() => addAdult(roomID)}
          >
            +
          </span>
        </div>
      )}

      {field?.attr.values && (
        <>
          <ExpandIcon isOpen={isActiveSelect} onClick={() => setIsActiveSelect(!isActiveSelect)} className="exp-input relative" />
          <div
            className={`${field.attr.className} ${!isActiveSelect ? "hidden" : "block"
              } bg-white text-blue-100 text-sm py-2 rounded-xl rounded-t-none border border-t-0 border-blue-8 -mt-1 z-1 absolute max-h-48 overflow-scroll`}
          >
            {field.attr.values.map((val, id) => {
              if (typeof val === 'object') {
                return Object.entries(val).map(e => (<span
                  key={Math.random()}
                  onClick={() => setInput(e[1], field.name)}
                  className={`py-1 px-5 hover:bg-blue-50 hover:text-white cursor-pointer block`}
                  style={{ width: inputWidth - 2 }}
                >
                  {e[1]}
                </span>))
              } else {

                return (<span
                  key={Math.random()}
                  onClick={() => setInput(val, field.name)}
                  className={`py-1 px-5 hover:bg-blue-50 hover:text-white cursor-pointer block`}
                  style={{ width: inputWidth - 2 }}
                >
                  {val}
                </span>
                )
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default Input;
