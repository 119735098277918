import { ResultRow } from "./ResultRow"

export const ResultHotelsRegions = ({ list, onClick, inputWidth }) => {
    return (<>
        {list.regions && (
            <div className="text-blue-50 mt-4 ps-4 text-9">Regiony</div>
        )}
        {list.regions.map((region, idx) => (
            <ResultRow key={idx} type="regions" obj={region} onClick={(type, obj) => onClick(type, obj)} inputWidth={inputWidth} />
        ))
        }

        {list.hotels && (
            <div className="text-blue-10 mt-4 ps-4 text-9">Hotele</div>
        )}
        {list.hotels.map((hotel, id) => (
            <ResultRow key={id} type="hotels" obj={hotel} onClick={(type, obj) => onClick(type, obj)} inputWidth={inputWidth} />
        ))
        }
    </>
    )

}