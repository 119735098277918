"use client";
import { createContext, useContext, useState } from "react";

export const SearchContext = createContext({
  destination: "",
  setDestination: () => { },
  checkin: "",
  setCheckIn: () => { },
  checkout: "",
  setCheckOut: () => { },
  room: 0,
  setRoom: () => { },
  adult: 1,
  setAdult: () => { },
  child: 0,
  setChild: () => { },
  residency: null,
  setResidency: () => { },
  meal: "",
  setMealTypeHandler: () => { },
  rates: "",
  setRates: () => { },
  early_checkin: "",
  late_checkout: "",
  searchStep: null,
  setSearchStep: () => { },
  freeCancel: '',
  rooms: [],
  setRooms: () => { },
  searchResult: null,
  setFieldHandler: () => { },
  setChildRooms: () => { },
  removeChildRooms: () => { },
  meal_types: "",
  searchParams: {},
  setSearchParams: () => { },
  language: '',
});

export const SearchContextProvider = ({ children }) => {
  const [destination, setDestination] = useState(null);
  const [checkin, setCheckIn] = useState(null);
  const [checkout, setCheckOut] = useState(null);
  const [room, setRoom] = useState(1);
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [rooms, setRooms] = useState([
    { adult: 1, children: 0, childrenArr: [] },
  ]);
  const [meal, setMealType] = useState(null);
  const [rates, setStars] = useState(null);
  const [early_checkin, setCheckinEarlyTime] = useState(null);
  const [late_checkout, setCheckinLaterTime] = useState(null);
  const [residency, setResidency] = useState();
  const [freeCancel, setFreeCancel] = useState(false);
  const [searchStep, setSearchStep] = useState(0)
  const [filter, setFilter] = useState(null)
  sessionStorage.setItem('rooms', JSON.stringify(rooms))
  // const setDestinationHandler = (str) => {
  // setDestination(str);
  // sessionStorage.setItem('destination', JSON.stringify(str))
  // };
  const setAdultRooms = (roomId, val) => {
    setRooms(
      rooms.map((room, id) => {
        if (id === roomId) {
          const obj = {
            ...room,
            adult: val,
          }
          sessionStorage.setItem('rooms', JSON.stringify(obj))
          return obj;
        } else {
          sessionStorage.setItem('rooms', JSON.stringify(room))
          return room;
        }
      })
    );
  };

  const setChildRooms = (roomId, val) => {
    setRooms(
      rooms.map((room, id) => {
        if (id === roomId) {
          const obj = {
            ...room,
            children: room.children + 1,
            childrenArr: [...room.childrenArr, val],
          }
          sessionStorage.setItem('rooms', JSON.stringify(obj))
          return obj;
        } else {
          sessionStorage.setItem('rooms', JSON.stringify(room))
          return room;
        }
      })
    );
  };
  const removeChildRooms = (roomId, val) => {
    setRooms(
      rooms.map((room, id) => {
        if (id === roomId) {
          room.childrenArr.splice(val, 1);
          const obj = {
            ...room,
            children: room.children - 1,
          }
          sessionStorage.setItem('rooms', JSON.stringify(obj))
          return obj;
        } else {
          sessionStorage.setItem('rooms', JSON.stringify(room))
          return room;
        }
      })
    );
  };
  // const setAdultHandler = (roomId, val) => {
  //     setRooms(prevState => {
  //         return [
  //             ...prevState,
  //
  //         ]
  //     })
  //     setAdultRooms()
  // }
  // const setRoomHandler = () => {
  //
  // }
  // const setChildHandler = () => {
  //
  // }
  // const setRoomsHandler = () => {
  //
  // }

  // handler for checkboxes
  const setCheckboxTypeHandler = (el, fieldName) => {
    if (fieldName === "meal_types") {
      const arr = meal ? meal.split(process.env.NEXT_PUBLIC_DELIMETER_DOT) : [];
      if (arr.indexOf(el.shortcut) === -1) {
        setMealType((prevState) => {
          return !prevState || prevState.length === 0
            ? (prevState = el.types)
            : (prevState += process.env.NEXT_PUBLIC_DELIMETER_DOT + el.types);
        });
      } else {
        // sessionStorage.setItem('meal', arr.filter((a) => a !== el.shortcut).join(","))
        setMealType(arr.filter((a) => a !== el.shortcut).join(process.env.NEXT_PUBLIC_DELIMETER_DOT));
      }
    }
    if (fieldName === "rates") {
      const arr = rates ? rates.split(process.env.NEXT_PUBLIC_DELIMETER_OR) : [];
      if (arr.indexOf(el.shortcut) === -1) {
        setStars((prevState) => {
          return !prevState || prevState.length === 0
            ? (prevState = el.shortcut)
            : (prevState += process.env.NEXT_PUBLIC_DELIMETER_OR + el.shortcut);
        });
      } else {
        // sessionStorage.setItem('rates', arr.filter((a) => a !== el.shortcut).join(","))
        setStars(arr.filter((a) => a !== el.shortcut).join(process.env.NEXT_PUBLIC_DELIMETER_OR));
      }
    }
  };

  // handler for input -> select
  const setCheckInTimeHandler = (time, fieldName) => {
    if (fieldName === "early_checkin") {
      setCheckinEarlyTime(time);
      sessionStorage.setItem('early_checkin', time)
    }
    if (fieldName === "late_checkout") {
      setCheckinLaterTime(time);
      sessionStorage.setItem('late_checkout', time)
    }
  };

  // handler for other fields if not supported separately
  const setFieldHandler = (fieldName, val) => {
    switch (fieldName) {
      case ("freeCancel" || 'free_cancel'):
        setFreeCancel(val);
        sessionStorage.setItem('free_cancel', val)
        break;
    }
  };

  return (
    <SearchContext.Provider
      value={{
        adult,
        checkin,
        checkout,
        early_checkin,
        late_checkout,
        child, // setChild: setChildHandler,
        destination,
        freeCancel,
        meal,
        residency,
        removeChildRooms,
        room, // setRoom: setRoomHandler,
        rooms,
        setAdult,
        setAdultRooms,
        setCheckboxTypeHandler,
        setCheckInTimeHandler,
        setChildRooms,
        setCheckIn,
        setCheckOut,
        setCheckinEarlyTime,
        setCheckinLaterTime,
        setDestination,
        setFieldHandler,
        setResidency,
        setRooms,
        rates,
        searchStep,
        setSearchStep,
        filter,
        setFilter
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
export const useSearchContext = () => {
  return useContext(SearchContext);
};
