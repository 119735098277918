import { ResultRowGeneric } from "@/components/molecules/search/ResultRowGeneric";
import { ResultRowHotels } from "@/components/molecules/search/ResultRowHotels";
import { useCountriesLocalesContext } from "@/contexts/CountriesLocalesContext"
import { useLangContext } from "@/contexts/LangContext";
import { _ln18 } from "@/lib/functions";


export const ResultRow = ({ obj, type, onClick, inputWidth }) => {
    const { countriesLoc } = useCountriesLocalesContext();
    const { ln18 } = useLangContext();

    if (type === 'hotels') {
        return (
            <ResultRowHotels
                obj={obj}
                onClick={onClick}
                inputWidth={inputWidth}
                countriesLoc={countriesLoc}
                ln18={ln18}
                type={type}
            />
        );
    }

    return (
        <ResultRowGeneric
            obj={obj}
            onClick={onClick}
            inputWidth={inputWidth}
            countriesLoc={countriesLoc}
            ln18={ln18}
            type={type}
        />
    );
}