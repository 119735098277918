import { useLangContext } from "@/contexts/LangContext";
import { _ln18 } from "@/lib/functions";


export const ResultRowGeneric = ({ obj, type, countriesLoc, onClick, inputWidth, ln18 }) => {

    return (
        <span onClick={() => onClick(type, obj)}
            className={`py-1 px-5 hover:bg-blue-50 hover:text-white cursor-pointer block`}
            style={{ width: inputWidth - 2 }}
        >
            {obj.name}, <span className="text-blue-10">{obj.country_code ? countriesLoc?.find(c => c.code === obj?.country_code?.toLowerCase())['name'] : ''}, {_ln18(obj.type, ln18)}</span>
        </span>
    )
}