import { useEffect, useState } from "react";
import { ResultHotelsRegions } from "@/components/molecules/search/ResultHotelsRegions";

const InputListSelect = ({ isActiveSelect, resultList, onClick, inputWidth }) => {
    const [results, setResults] = useState()
    const [typeResult, setTypeResult] = useState(resultList.hotels ? 'destination' : resultList.countries ? 'countries' : resultList.search ? 'search' : '')
    useEffect(() => {
        // update , zakomentowane dla dynamic filter list
        // if (!results)
        setResults(resultList.hotels || resultList.countries?.data || resultList.search)
    }, [resultList])
    return (
        <div className={` ${!isActiveSelect ? 'hidden' : 'flex flex-col'} shadow-1 shadow-gray-300 bg-white text-blue-100 text-sm py-2 rounded-xl rounded-t-none border border-t-0 border-blue-8 pt-2 -mt-1 z-10 absolute z-4 overflow-scroll w-full`}>
            {typeResult === 'destination' && results && <ResultHotelsRegions onClick={(type, obj) => onClick(type, obj)} inputWidth={inputWidth} list={resultList} />}

            {typeResult === 'countries' && results && results.map((el, eid) => (
                
                <span key={eid} onClick={() => onClick(typeResult, el)}
                    className={`py-1 px-5 hover:bg-blue-50 hover:text-white cursor-pointer block`}
                    style={{ width: inputWidth - 2 }}>
                    {el.name}
                </span>
            ))}

            {typeResult === 'search' && results && results.map((el, eid) => (
                <span key={eid} onClick={() => onClick(typeResult, el)}
                    className={`py-1 px-5 hover:bg-blue-50 hover:text-white cursor-pointer block relative z-5`}
                    style={{ width: inputWidth - 2 }}>
                    {el.name}
                </span>
            ))}
        </div>
    )
}

export default InputListSelect;