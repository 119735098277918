'use client';
import { createContext, useContext, useEffect, useState } from "react";
import { LN18 } from "@/graphql/Ln18.graphql";
import { useQuery } from "@apollo/client";
import { getCookieClient } from "@/api/actions";

export const LangContext = createContext({
    ln18: {},
    setLn18: () => { },
})

export const LangContextProvider = ({ children }) => {
    const [ln18, setLn18] = useState({});
    const queryLn18 = useQuery(LN18, {
        variables: { lang: getCookieClient("lang") },
    });
    useEffect(() => {
        if (queryLn18?.data?.ln18) setLn18(queryLn18?.data?.ln18);
    }, [queryLn18])

    return (<LangContext.Provider value={{ ln18, setLn18 }}>{children}</LangContext.Provider>)
}
export const useLangContext = () => {
    return useContext(LangContext)
}